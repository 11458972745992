import { configureStore } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/lib/storage/session";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import authReducer from "./authSlice";
import proposalsReducer from "./proposalsSlice";
import selectedPostsReducer from "./postsSlice";

const transform = createTransform(
  (inboundState) => JSON.stringify(inboundState), // Almacenar en sessionStorage
  (outboundState) => JSON.parse(outboundState) // Leer desde sessionStorage
);

const persistConfig = {
  key: "auth",
  storage: sessionStorage,
  version: 1,
  /*   whitelist: ["isAuthenticated", "token", "role"],  */

  transforms: [transform],

  debug: true,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    proposals: proposalsReducer,
    selectedPosts: selectedPostsReducer,
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
