import { useAppSelector } from "Hooks/useRedux";
import { Navigate, Outlet } from "react-router-dom";

type ProtectedRouteProps = {
  redirectTo?: string;
  children?: React.ReactElement | null;
};

export const ProtectedRoutes: React.FC<ProtectedRouteProps> = ({
  redirectTo = "/",
  children,
}) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  return children || <Outlet />;
};
