import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "Assets/translations/en.json";
import spTranslation from "Assets/translations/sp.json";

import enCareers from "Assets/translations/enCareers.json";
import spCareers from "Assets/translations/spCareers.json";

import enPillars from "Assets/translations/enPillars.json";
import spPillars from "Assets/translations/spPillars.json";

import enLegal from "Assets/translations/enLegal.json";
import spLegal from "Assets/translations/spLegal.json";

import enJobForm from "Assets/translations/enJobForm.json";
import spJobForm from "Assets/translations/spJobForm.json";

import enLogin from "Assets/translations/enLogin.json";
import spLogin from "Assets/translations/spLogin.json";

import enAbout from "Assets/translations/enAbout.json";
import spAbout from "Assets/translations/spAbout.json";

import spServices from "Assets/translations/spServices.json";

import spEnviroment from "Assets/translations/spEnviroment.json";

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: true,
    fallbackLng: "sp",
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          ...enTranslation,
          ...enCareers,
          ...enPillars,
          ...enLegal,
          ...enJobForm,
          ...enLogin,
          ...enAbout,
        },
      },
      sp: {
        translation: {
          ...spTranslation,
          ...spCareers,
          ...spPillars,
          ...spLegal,
          ...spJobForm,
          ...spLogin,
          ...spAbout,
          ...spServices,
          ...spEnviroment,
        },
      },
    },
  });

export default i18n;
