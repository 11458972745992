import { useTranslation } from "react-i18next";
import logoHorizontal from "Assets/images/logo-horizontal.png";

// Component
import Socials from "Components/Socials";

// Style
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer data-testid="footer">
      <img
        src={logoHorizontal}
        alt="Logo Baipro"
        className="logo"
        height={48}
      ></img>
      <div className="main">
        <div className="content">
          <p className="neocomplexx">Powered by Neocomplexx Group S.A.</p>
          <div className="content-links">
            <span className="link">{t(`footer.suscribe`)}</span>
            <a href="/privacy" className="link">
              {t(`footer.terms`)}
            </a>
          </div>
        </div>
        <Socials />
      </div>
    </footer>
  );
};

export default Footer;
