import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const MAX_POSTS_SELECTED = 3;

interface SelectedPostsState {
  selectedPosts: string[];
}

const initialState: SelectedPostsState = {
  selectedPosts: [],
};

export const selectedPostsSlice = createSlice({
  name: "selectedPosts",
  initialState,
  reducers: {
    togglePost: (state, action: PayloadAction<string>) => {
      const id = action.payload;

      if (state.selectedPosts.includes(id)) {
        state.selectedPosts = [...state.selectedPosts.filter((p) => p !== id)];
      } else if (state.selectedPosts.length < MAX_POSTS_SELECTED) {
        state.selectedPosts = [...state.selectedPosts, id];
      }
    },
    setAllPosts: (state, action: PayloadAction<string[]>) => {
      state.selectedPosts = action.payload;
    },
  },
});

export const { togglePost, setAllPosts } = selectedPostsSlice.actions;
export default selectedPostsSlice.reducer;
