import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: null,
  role: "",
  rehydrated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { token, role } = action.payload;
      state.isAuthenticated = true;
      state.token = token;
      state.role = role;
    },
    logout: (state) => {
      state.isAuthenticated = initialState.isAuthenticated;
      state.token = initialState.token;
      state.role = initialState.role;
    },
    rehydrate: (state, action) => {
      return {
        ...state,
        ...action.payload,
        role: action.payload.role ?? "Guest",
        rehydrated: true,
      };
    },
  },
});

export const { login, logout, rehydrate } = authSlice.actions;
export default authSlice.reducer;
